<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /
      <!-- <router-link :to="{name: 'admin-master' }" class="root">
               Librarian
            </router-link> -->
      <span class="child"> Librarian / Weed out Book </span>
    </v-row>
    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center"> Weed out Book
          <v-spacer></v-spacer> <v-btn
                  class="primary"
                  @click="getallWeedoutbooks()"
                  >WeedOut Books</v-btn
                >
      </v-card-title>

<!--dialog weedout-->

              <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog_weedout"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="series_close_data()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>All Weed Out Books List</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="series_close_data()"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="circular">
                    <v-progress-linear
                      indeterminate
                      height="25"
                      color="#393e46"
                    >
                      <strong style="color: #fff">Loading...</strong>
                    </v-progress-linear>
                  </div>
                </template>
                <v-card-title v-if="circular == false">
                  <v-text-field
                    v-model="search_book"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel
                      :data="allweedoutBooks"
                      :fields="header_weedout_excel"
                      worksheet="Books Data"
                      name="Books Data.xls"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="green"
                            size="40"
                            left
                            v-bind="attrs"
                            v-on="on"
                            >mdi-file-excel</v-icon
                          >
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table
                  :headers="header_weedout"
                  :items="allweedoutBooks"
                  :search="search_book"
                  v-if="circular == false"
                >
                     <template v-slot:item.date_of_entry="{ item }">
                {{item.date_of_entry.split("T")[0].split("-")[2]+"-"+item.date_of_entry.split("T")[0].split("-")[1]+"-"+item.date_of_entry.split("T")[0].split("-")[0]}}
          </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>











<!--end dialog weedout-->
        <center>
          <v-row  style="margin-top:20px" >
            <v-col sm="1" > <label style="font-weight: bold; color: blue"
                >Series : </label></v-col>
            <v-col sm="3">
              <v-autocomplete
            v-model="selectedseries"
            outlined
            :items="series_data"
            item-text="name"
            item-value="id"
            @change="setValue()"
          ></v-autocomplete>
            </v-col>
            <v-col sm="2"><label style="font-weight: bold; color: blue"
                >Accession No: </label></v-col>
            <v-col sm="3">
              <!-- <v-text-field
                outlined
                v-model="accno"
                dense
                class="text"
                placeholder="Enter Accession NO"
              ></v-text-field
            > -->
             <v-text-field
                v-model="accno"
                outlined
                dense
                ></v-text-field>
            </v-col>
            <v-col sm="3">
             <v-btn
              :disabled="!selectedseries"
              class="primary"
              @click="findBookItemInfo(accno)"
              >Fetch Details</v-btn
            >
            </v-col>
          </v-row>
        </center>

                <div v-if="bookInfo" class="cus-card">
                        <v-row>
                            <div style="width:80%">
                            <v-row style="padding: 0; margin: 0">
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label style="padding: 0; margin: 0" class="c-label"
                                    >Title</label
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{ bookinformation.title }}</span
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label style="padding: 0; margin: 0" class="c-label"
                                    >Accession No</label
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{accno }}</span
                                ></v-col
                                >
                                 
                            </v-row>
                            <v-row style="padding: 0; margin: 0">
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label style="padding: 0; margin: 0" class="c-label"
                                    >Author(s)</label
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{ bookinformation.author }}</span
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label style="padding: 0; margin: 0" class="c-label"
                                    >Publisher</label
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{ bookinformation.publisher }}</span
                                ></v-col
                                >
                            </v-row>
                            <v-row style="padding: 0; margin: 0">
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label class="c-label">Book Type</label></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{ bookinformation.booktype }}</span
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0" lg="2">
                                <label style="padding: 0; margin: 0" class="c-label"
                                    >Book Price</label
                                ></v-col
                                >
                                <v-col style="padding: 0; margin: 0"
                                ><span style="padding: 0; margin: 0"
                                    >: {{ bookinformation.bookprice }}</span
                                ></v-col
                                >
                            </v-row>
                            
                            </div>
                        </v-row>
                </div>
                 <div v-if="bookInfo" class="cus-card" >
                 <v-row>
                       <v-col sm="4">
                            <label style="font-weight: bold; color: blue"
                                >Weed Out Code: </label>
                            <v-autocomplete
                                v-model="selectedweedoutcode"
                                outlined
                                label="Select Weedout Code"
                                :items="code_list"
                                item-text="name"
                                item-value="id"
                            ></v-autocomplete>
                       </v-col>
                        <v-col sm="4">
                        <label style="font-weight: bold; color: blue"
                        >Weed Out Date: </label>
                            <v-menu 
                                v-model="showPicker6"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                dense
                                class="text"
                                full-width
                                max-width="290px"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="weedoutdate"
                                    placeholder="Select weedout Date"
                                    hint="YYYY/MM/DD"
                                    persistent-hint
                                    dense
                                    class="text"
                                    outlined
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="weedoutdate"
                                    no-title
                                    @input="showPicker6 = false"
                                ></v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-col  sm="4">
                            <label style="font-weight: bold; color: blue">Weedout Remark</label>
                            <v-textarea
                             persistent-hint
                                dense
                                class="text"
                              outlined
                              rows="2"
                              v-model="remark"
                            ></v-textarea>
                          </v-col>
                          <br>
                          <v-col  sm="4">
                            <label style="font-weight: bold; color: blue">Weedout Receipt Number</label>
                            <v-text-field
                             persistent-hint
                                    dense
                                    class="text"
                              outlined
                              rows="2"
                              v-model="rctnum"
                            ></v-text-field>
                          </v-col>
                           <v-col sm="4">
                        <label style="font-weight: bold; color: blue"
                        >Weed Out Receipt Date: </label>
                            <v-menu 
                                v-model="showPicker7"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                dense
                                class="text"
                                full-width
                                max-width="290px"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="weedoutRctdate"
                                    placeholder="Select weedout Date"
                                    hint="YYYY/MM/DD"
                                    persistent-hint
                                    dense
                                    class="text"
                                    outlined
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="weedoutRctdate"
                                    no-title
                                    @input="showPicker7 = false"
                                ></v-date-picker>
                        </v-menu>
                        </v-col>
                          <v-col  sm="4">
                                <v-btn style="margin-top:30px;margin-left:30px"
                                class="primary"
                                @click="approveweedout()"
                                >Approve</v-btn>
                        </v-col>
                    </v-row>
                 </div>  
                  <div v-if="weedoutArray.length > 0 " class="cus-card" >
                         <template>
                         
                    <v-data-table :headers="headers" :items="weedoutArray"  class="elevation-1" :search="search">
                    </v-data-table>
                </template>
                  </div>
       
         
        
     
                
      <!-- </v-card-text> -->
    </v-card>

    
    <v-overlay :value="overlay"> loading.. </v-overlay>
    
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
      code_list:[],
    series_data:[],
    weedoutArray:[],
    remark:"",
    rctnum:"",
    dialog_weedout:false,
    circular:false,
    showPicker7:false,
    showPicker6:false,
    selectedseries:"",
    selectedweedoutcode:"",
    weedoutdate:"",
    weedoutRctdate:"",
    accno:"",
    menu2: false,
    renew_dialog: false,
    return_dialog: false,
    valid: false,
    overlay: false,
    isempselected: true,
    isbookselected: false,
    wtable:false,
     search_book: "",
    userid: "",
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    book_upload_dialog:false,
    snackbar_msg: "",
    bookLimit: false,
    color: "",
    snackbar: false,
    search: "",
    bookinformation: [],
    allBookInfo: [],
    selectedbookitem: "",
    org: "",
    inSave: true,
    dialog: false,
    prn: "",
    accno: "",
    date: null,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession Number",
        align: "left",
        sortable: true,
        value: "acc_no",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },

      {
        text: "Cost",
        align: "left",
        sortable: true,
        value: "cost",
      },
       {
        text: "Weed out Code",
        align: "left",
        sortable: true,
        value: "code",
      },
      {
        text: "Weed out remark",
        align: "left",
        sortable: true,
        value: "weedoutremark",
      },
      {
        text: "WeedOut Date",
        align: "left",
        sortable: true,
        value: "wo_date",
      },
      {
        text: "Receipt Number",
        align: "left",
        sortable: true,
        value: "rctnumber",
      },
      {
        text: "Receipt Date",
        align: "left",
        sortable: true,
        value: "wo_RCTdate",
      },
    ],
     header_weedout: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession Number",
        align: "left",
        sortable: true,
        value: "acc_no",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },

      {
        text: "Cost",
        align: "left",
        sortable: true,
        value: "cost",
      },
       {
        text: "Weed out Code",
        align: "left",
        sortable: true,
        value: "code",
      },
      {
        text: "Weed out remark",
        align: "left",
        sortable: true,
        value: "weedoutremark",
      },
      {
        text: "WeedOut Date",
        align: "left",
        sortable: true,
        value: "wo_date",
      },
      {
        text: "Receipt Number",
        align: "left",
        sortable: true,
        value: "rctnumber",
      },
      {
        text: "Receipt Date",
        align: "left",
        sortable: true,
        value: "wo_RCTdate",
      },
    ],
    header_weedout_excel:{
      "Sr no": "srno",
     "Accession Number": "acc_no",
      "Title": "title",
      "Author": "author",
     "Cost": "cost",
     "Weed out Code": "code",
     "Weed out remark": "weedoutremark",
     "WeedOut Date": "wo_date",
      "Receipt Number": "rctnumber",
     "Receipt Date": "wo_RCTdate",
      },
    headers1: [
      {
        text: "Sr no",
        sortable: true,
        value: "srno",
      },
      {
        text: "ISBN",
        sortable: true,
        value: "isbn",
      },
      {
        text: "Title",
        sortable: true,
        value: "title",
      },
    

    ],
    bookInfo: false,
    bookitemid: null,
    bookhistory: [],
    employeehistory: [],
   
  }),
  watch: {
    
     date(val) {
      this.date = this.formatDate(this.date);
    }
  },
  mounted() {
    this.onLoad();
  },
    methods: {
    onLoad() {
        
      this.overlay = true;
      axios
        .post("/Librarian/getWeedoutpagedata")
        .then((res) => {
          if (res.data.msg == "200") {
            this.series_data = res.data.series_data;
            this.overlay = false;
        console.log("this.series_data");
        console.log(this.series_data);
        if(res.data.data)
        this.showSnackbar("red", res.data.data); 
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    setValue() {
      if (this.selectedseries != "ALL" ) {
        for (var k = 0; k < this.series_data.length; k++) {
          if (this.series_data[k].id == this.selectedseries)
            this.accno = this.series_data[k].short_name;
        }
      } else this.accno = "";
    },
    series_close_data()
    {
      this.dialog_weedout = false ;
    
    },
     getallWeedoutbooks(){
    //   alert("iiiiinnnnnnnnn");
      this.circular =true;
        axios
          .post("/Librarian/getallWeedoutbooks")
          .then((res) => {
            if (res.data.msg == "200") {
              //this.overlay = false;
              this.allweedoutBooks = res.data.allweedoutArray;
              this.circular=false;
              this.dialog_weedout=true;
               console.log("allweedoutBooks--------")
           console.log(this.allweedoutBooks)
            } else {
              this.showSnackbar("red", res.data.msg);
              this.bookInfo = false;
              this.overlay = false;
            }
          });
    },

     findBookItemInfo(item) {
       // alert(item);
      this.overlay = true;
      if (item != "") {
        var params = { accno:item,
        selectedseries:this.selectedseries};
        axios
          .post("/Librarian/getbookdataWeedout", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.bookInfo = true;
              this.bookinformation = res.data.bookinformation;
              this.weedoutArray = res.data.weedoutArray;
              this.code_list = res.data.code_list;
              this.bookitemid = res.data.bfid;
               console.log("weedoutArray--------")
           console.log(res.data.weedoutArray)
            } else {
              this.showSnackbar("red", res.data.msg);
              this.bookInfo = false;
              this.overlay = false;
            }
          });
      } else {
        this.showSnackbar("red", "please enter accession number");
       }
    },
    approveweedout(){
      //  alert("in approveweedout");
        if(this.weedoutdate == ""){
          this.showSnackbar("red", "Please select weed out date");
        }else if(this.selectedweedoutcode ==""){
             this.showSnackbar("red", "Please select weed out date");
        }else if(this.remark==""){
             this.showSnackbar("red", "Please write Remark");
        }else{
        var params = { accno:this.accno ,weedoutdate: this.weedoutdate, code:this.selectedweedoutcode,
        remark:this.remark,rctnum:this.rctnum,weedoutRctdate:this.weedoutRctdate,selectedseries:this.selectedseries };
        axios
          .post("/Librarian/approveweedout", params)
          .then((res) => {
            if (res.data.code == "success") {
              this.overlay = false;
              this.weedoutArray = res.data.weedoutArray;

              this.showSnackbar("green", "Book weed out successfully");
            
            } else {
              this.showSnackbar("red", res.data.msg);
              
              this.overlay = false;
            }
          });
      
    
    }
    },

    // onLoad() {
    //   this.overlay = true;
    //   axios
    //     .post("/IssueBook/getEmpMemData")
    //     .then((res) => {
    //       if (res.data.msg == "200") {
    //         this.empmemberlist = res.data.userlist;
    //         this.programList = res.data.programList;
    //         this.yearList = res.data.yearList;
    //         // this.onload1();
    //       }
    //     })
    //     .catch((error) => {
    //       this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
    //       window.console.log(error);
    //     })
    //     .finally(() => {
    //       // var overlay = false;
    //     });
    // },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

   
   

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
.add-section {
  display: block;
  margin: 1rem;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.v-messages {
  display: none;
}
</style>
